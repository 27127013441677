import { faCheck, faHouse, faUser } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cva } from 'class-variance-authority';
import { useTranslation } from 'next-i18next';
import { ReactNode, useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';

import { useRouter } from '@boss/hooks';
import type { Crumble, Environment, LocalizedSlugs, Navigation, Nullable } from '@boss/types/b2b-b2c';
import { BreadCrumbs, Container, Footer, Portal, RefreshBanner, ScrollToTop } from '@boss/ui';

import SubscribeForm from '../components/Form/SubscribeForm';
import Header from '../components/Header';
import PWAWrapper from '../components/PWAWrapper';
import { displayPWAPopup } from '../utils';

type Props = {
  children: ReactNode;
  environment?: Environment;
  preview?: boolean;
  navigation: Nullable<Navigation>;
  localizedSlugs: LocalizedSlugs;
  className?: string;
  crumbles?: Crumble[];
  breadcrumbWrapperClassName?: string;
  breadcrumbClassName?: string;
  breadcrumbHasSmallSpacing?: boolean;
  containerWrapperClassName?: string;
  childrenClassName?: string;
  variant?: 'primary' | 'secondary' | 'tertiary';
  type?: 'default' | 'simple';
  hideDevBanner?: boolean;
  isAccountPage?: boolean;
};

const WrapperStyles = cva('', {
  variants: {
    variant: {
      primary: '',
      secondary: '',
      tertiary: 'md:bg-white-dark',
    },
  },
});

const Layout = ({
  children,
  environment = process.env.NEXT_PUBLIC_CONTENTFUL_ENVIRONMENT as Environment,
  preview,
  navigation,
  localizedSlugs,
  className,
  crumbles,
  breadcrumbClassName,
  breadcrumbWrapperClassName,
  childrenClassName,
  containerWrapperClassName,
  variant = 'primary',
  type = 'default',
  hideDevBanner = false,
  isAccountPage = false,
}: Props) => {
  const isDev = environment !== 'master' || preview;
  const { locale, asPath } = useRouter();

  const { t } = useTranslation('common');

  const [currentTrustbuilderIndex, setCurrentTrustbuilderIndex] = useState(0);

  useEffect(() => {
    if (!navigation?.trustbuildersHeader?.length) {
      return;
    }

    const interval = setInterval(() => {
      setCurrentTrustbuilderIndex(prev => (prev + 1) % (navigation?.trustbuildersHeader?.length ?? 1));
    }, 3500); // Change every 3.5 seconds

    return () => clearInterval(interval);
  }, [navigation?.trustbuildersHeader]);

  return (
    <>
      {!hideDevBanner && isDev && (
        // FIXME
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        <Portal>
          <RefreshBanner environment={environment} preview={preview} />
        </Portal>
      )}
      <div
        className={twMerge(
          isDev && !hideDevBanner && 'dev-wrapper',
          'min-h-[100vh]',
          WrapperStyles({ variant }),
          className,
        )}
      >
        {navigation && <Header localizedSlugs={localizedSlugs} nav={navigation} type={type} />}
        {navigation?.trustbuildersHeader?.length && (
          <Container>
            <div className="hidden pb-2 pt-5 md:flex md:gap-10">
              {navigation?.trustbuildersHeader?.map((item, index) => (
                <div className="flex items-center gap-2 text-sm text-gray-600" key={`trustbuilder-${index}`}>
                  <FontAwesomeIcon className="text-green" icon={faCheck} />
                  {item}
                </div>
              ))}
            </div>
            <div className="relative h-8 overflow-hidden pt-12 md:hidden">
              {navigation?.trustbuildersHeader?.map((item, index) => (
                <div
                  className={`absolute inset-0 flex items-center justify-center gap-2 text-sm text-gray-600 transition-all duration-1000 ${
                    index === currentTrustbuilderIndex
                      ? 'translate-x-0 opacity-100'
                      : index < currentTrustbuilderIndex
                      ? '-translate-x-full opacity-0'
                      : 'translate-x-full opacity-0'
                  }`}
                  key={`trustbuilder-mobile-${index}`}
                >
                  <FontAwesomeIcon className="text-green" icon={faCheck} />
                  {item}
                </div>
              ))}
            </div>
          </Container>
        )}
        {!!crumbles?.length && (
          <Container className={twMerge('md:pt-7', breadcrumbWrapperClassName)}>
            <BreadCrumbs
              className={breadcrumbClassName}
              crumbles={crumbles}
              homeLabel={t('home')}
              icon={isAccountPage ? faUser : faHouse}
              locale={locale}
              path={asPath}
            />
          </Container>
        )}
        <Container className={twMerge('flex flex-grow flex-col', containerWrapperClassName)}>
          <div className={twMerge(childrenClassName)}>{children}</div>
          {navigation && (
            <Footer
              className="self-end"
              form={<SubscribeForm />}
              legalLinks={navigation.legalLinks}
              locale={locale}
              logo={navigation.footerLogo}
              navigationItems={navigation.footerNavigationItems}
              paymentMethods={navigation.paymentMethods}
              socialLinks={navigation.socialLinks}
              type={type}
            />
          )}
        </Container>
        <ScrollToTop />
        {displayPWAPopup && <PWAWrapper />}
      </div>
    </>
  );
};

export default Layout;
